import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;

  ${props =>
    props.centered &&
    css`
      justify-content: center;
    `}

  & > * {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  & > *:not(last-child) {
    margin-right: 1rem;
  }

  ${props =>
    props.columns &&
    css`
      @media (min-width: 680px) {
        padding-left: calc(50% + 10px);
      }
    `}
`
const Actions = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default Actions
