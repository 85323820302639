import styled from 'styled-components'

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
`

const Column = styled.div`
  flex: 1;
  padding-left: 20px;
`

Columns.Column = Column

export default Columns
