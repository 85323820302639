import React from 'react'
import styled from 'styled-components'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Button from '../components/Button'
import Actions from '../components/Actions'
import Spacer from '../components/Spacer'
import Columns from '../components/Columns'
import Label from '../components/Label'

const Services = styled.div`
  ul {
    padding: 0 0 0 1.125rem;
    margin: 0;
  }
`

const StyledColumns = styled(Columns)`
  @media (max-width: 680px) {
    flex-direction: column;

    & > div {
      margin-bottom: 2rem;
    }

    & > div:last-child {
      margin-bottom: 0;
    }
  }
`

const CompetenceTemplate = ({ pageContext }) => {
  const intl = useIntl()
  const { title, content, services } = pageContext
  const overviewPage =
    intl.locale === 'de' ? '/de/taetigkeitsbereiche' : '/en/competences'
  const contactPage = intl.locale === 'de' ? '/de/kontakt' : '/en/contact'

  return (
    <Layout>
      <SEO title={title} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'competence.title' })}>
          {title}
        </Title>
        <StyledColumns>
          <Columns.Column>
            {documentToReactComponents(content.json)}
          </Columns.Column>
          <Columns.Column>
            <Services>
              <Label>
                <FormattedMessage id="competences.focus" />
              </Label>
              {documentToReactComponents(services.json)}
            </Services>
          </Columns.Column>
        </StyledColumns>
        <Spacer size="small" />
        <Actions columns>
          <Button to={contactPage}>
            <FormattedMessage id="competences.contact" />
          </Button>
          <Button to={overviewPage}>
            <FormattedMessage id="competences.back" />
          </Button>
        </Actions>
        <Spacer size="medium" />
      </Content>
    </Layout>
  )
}

export default CompetenceTemplate
